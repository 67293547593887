<template>
  <div class="category-title">
    <v-img
      v-if="headerImage && !$vuetify.breakpoint.xs"
      dark
      :src="headerImage"
      :height="height"
      class="elevation-0 rounded-0 d-flex align-center"
      @load="loaded = true"
    >
      <div class="layer"></div>
      <div
        class="text-h4 primary--text text--lighten-1 text-center text-sm-left font-weight-bold"
      >
        {{ title }}
      </div>
    </v-img>
    <div
      v-else
      class="text-h4 primary--text text--lighten-1 text-center text-sm-left font-weight-bold"
    >
      {{ title }}
    </div>
  </div>
</template>
<script>
import get from "lodash/get";

export default {
  name: "PageTitle",
  props: {
    page: { type: Object, required: true },
    height: { type: String, default: "170px" }
  },
  computed: {
    title() {
      return get(this.page, "title.rendered");
    },
    headerImage() {
      return get(this.page, "featured_image_url");
    }
  }
};
</script>
