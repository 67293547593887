<template>
  <v-container fluid class="hidden-xs-only pa-0">
    <v-breadcrumbs :items="items"> </v-breadcrumbs>
  </v-container>
</template>
<style lang="scss" scope="global"></style>
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  jsonld() {
    const items = this.items.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": item.to,
        name: item.text
      }
    }));
    return {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items
    };
  }
};
</script>
