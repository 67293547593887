<template>
  <div class="flyers">
    <page-title class="hidden-xs-only" :page="page" />
    <Breadcrumb :items="breadcrumb" />
    <div v-html="content" @click="clicked"></div>
    <v-container class="mt-0 mt-md-10">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          class="col px-9 px-sm-6 py-4 py-sm-0"
          v-for="proposal in proposals"
          :key="proposal.id"
        >
          <v-container class="card-container pa-0">
            <h2
              class="font-weight-bold text-center text-uppercase"
              v-html="proposal.title.rendered"
            ></h2>
            <h4 class="text-center" v-html="proposal.testo_validit"></h4>
          </v-container>
          <v-card
            rounded
            :to="{ name: 'Flyer', params: { volantino: proposal.slug } }"
            elevation="2"
            class="proposal-img d-flex justify-center align-center"
          >
            <v-img
              :src="proposal.featured_image_url"
              :alt="proposal.title.rendered"
              class="offerta-image align-center"
            >
              <v-icon class="magnify" x-large color="white">
                mdi-magnify-plus-outline
              </v-icon>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CMSService from "~/service/cmService.js";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Flyers",
  components: {
    Breadcrumb,
    PageTitle
  },
  mixins: [page, clickHandler],
  data() {
    return {
      proposals: []
    };
  },
  async mounted() {
    this.fetchPage("volantini");
    let res = await CMSService.getCustomPostByFilters("offerta");
    this.proposals = res.data;
  }
};
</script>

<style scoped lang="scss">
.flyers {
  .page-title {
    margin-bottom: 95px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-bottom: 58px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      h2 {
        height: 60px;
      }
      h4 {
        height: 42px;
      }
    }
    h2 {
      color: $secondary !important;
      line-height: 33px;
    }
    h4 {
      font-weight: 400;
      line-height: 17px;
    }
  }
  .col {
    margin-bottom: 60px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-bottom: 12px;
    }
    .card-container {
      min-height: 50px;
      margin-bottom: 10px;
      h2 {
        font-size: 20px;
        color: $secondary;
      }
      h4 {
        font-weight: 400 !important;
      }
    }

    .proposal-img .magnify {
      display: none;
      width: 100%;
      // @media #{map-get($display-breakpoints, 'xs-only')} {
      //   display: flex;
      // }
    }

    .offerta-image {
      &:hover {
        -webkit-filter: brightness(60%);
        filter: brightness(60%);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;

        .magnify {
          display: flex;
          -webkit-filter: brightness(150%);
          filter: brightness(150%);
        }
      }
    }
  }
}
</style>
